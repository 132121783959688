// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-docs-index-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/src/templates/docs/index.js" /* webpackChunkName: "component---src-templates-docs-index-js" */),
  "component---src-pages-index-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oss-index-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/src/pages/oss/index.js" /* webpackChunkName: "component---src-pages-oss-index-js" */),
  "component---src-pages-oss-react-native-firebase-index-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/src/pages/oss/react-native-firebase/index.js" /* webpackChunkName: "component---src-pages-oss-react-native-firebase-index-js" */),
  "component---src-pages-what-we-do-index-js": () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/src/pages/what-we-do/index.js" /* webpackChunkName: "component---src-pages-what-we-do-index-js" */)
}

exports.data = () => import("/Users/elliothesp/Documents/Code/Invertase/new-docs-site/.cache/data.json")

