/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { transitionElement } from './utils';

require("prismjs/themes/prism.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");

/**
 *
 * @param element
 * @param props
 * @return {*}
 */
export const wrapPageElement = transitionElement;
