import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const DELAY = 10;

/**
 *
 * @param element
 * @param props
 * @return {*}
 */
export const transitionElement = ({ element, props }) => {
  const RoutesContainer = posed.div({
    enter: { opacity: 1, delay: DELAY, delayChildren: DELAY },
    exit: { opacity: 0 },
  });

  return (
    <PoseGroup>
      <RoutesContainer key={props.location.pathname}>{element}</RoutesContainer>
    </PoseGroup>
  )
};
