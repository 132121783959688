module.exports = [{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-98196653-2"},
    },{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#e88634","showSpinner":false},
    },{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://invertase.io"},
    },{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/elliothesp/Documents/Code/Invertase/new-docs-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
